export function getAutofillFromURL() {
  const urlParams = new URLSearchParams(window.location.search)
  const autoFillEncoded = urlParams.get("autofill")
  if (!autoFillEncoded) {
    return
  }

  const decodedAutofill = decodeURIComponent(window.atob(autoFillEncoded))
  const parsedAutofill = JSON.parse(decodedAutofill.replaceAll("'", '"'))
  const formattedFormFields = {}
  Object.entries(parsedAutofill).forEach(([key, value]) => {
    formattedFormFields[key] = value.value
  })

  return formattedFormFields
}
