import { Button, Text } from "iq-blueberry"
import Img from "@mkt/ui/components/common/Img"
import DigioLogo from "@mkt/iq/static/images/digio-white-logo.svg"
import * as S from "./styles"
import { navigate } from "gatsby"

const DigioRedirectModal = ({ isOpenSortingHat }) => {
  return isOpenSortingHat ? (<></>) :
    <div css={S.content}>
      <div>
        <div css={S.infos}>
          <Img src={DigioLogo} alt="Foto do cartão" />
          <Text
            as="h6"
            variant="heading-six"
          >
            Você está a poucos passos de conquistar seu cartão Digio!
          </Text>
          <p css={S.fisrtSubtitle}>
            Fique de olho no seu e-mail,
            que o Digio vai enviar um
            <span css={S.textBold}>
             {" "} token para você continuar o pedido
              do cartão.
            </span>
          </p>
          <p css={S.secondSubtitle}>
            Pra garantir, dá uma olhada no spam também {":)"}
          </p>
        </div>
        <Button size="large" expand="x" justify="center" onClick={() => {navigate("/")}}>Ok, entendi!</Button>
      </div>
    </div>
  }

export default DigioRedirectModal
