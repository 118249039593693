import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"

export const formFlow = css`
  display: flex;
  /* min-height: calc(100vh - 100px); */
  position: relative;

  ${above["m"]} {
    display: block;
  }

  .form-flow__loading-wrapper {
    background-color: white;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .iq-radio-field__container {
    ${above["m"]} {
      column-gap: 20px;
      display: flex;
    }

    .iq-radio-field__option {
      border: 1px solid #b5b5b5;
      border-radius: 8px;
      padding: 2px 0px;
    }
  }
`
export const formFlowContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  /* min-height: calc(100vh - 100px); */
  width: 100%;

  ${above["m"]} {
    margin-top: 0;
  }

  .iq-text {
    text-align: center;
  }

  .iq-text[class*="title"] {
    margin-bottom: 16px;
  }

  .iq-text[class*="common"] {
    margin-bottom: 32px;
    text-align: left;
  }
`

export const formFlowFirebolt = css`
  margin-bottom: 16px;
  height: 100%;

  .iq-radio-field__icon::after {
    border: none;
  }
`
