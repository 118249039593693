const replaceAppsflyerUrlParams = ({
  url,
  cardSlug,
  userProductId,
  anonymousId,
}) => {
  const urlReplaced = url
    ?.replace("(campaign)", cardSlug)
    .replace("(transactionID)", anonymousId)

  return urlReplaced
}

export default replaceAppsflyerUrlParams
