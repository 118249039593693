import { Text } from "iq-blueberry"

import * as S from "./styles"

const PrivacyPolicy = () => (
  <div css={S.PrivacyPolicy}>
    <Text as="p" defaultColor="common" variant="body-micro">
      Ao avançar, você concorda com os{" "}
      <a href="/termos-e-condicoes/" target="_blank">
        Termos de Uso
      </a>
      <br /> e{" "}
      <a href="/politica-de-privacidade/" target="_blank">
        Política de Privacidade
      </a>
    </Text>
  </div>
)

export default PrivacyPolicy
