const replaceItauPfUrlParams = ({
  url,
  fireboltId,
}) => {
  const urlReplaced = url
    .replace("(identifier)", fireboltId)

  return urlReplaced
}

export default replaceItauPfUrlParams
