import { Button, Conditional } from "iq-blueberry"
import PropTypes from "prop-types"
// import OutlineArrowLeftOne from "iq-blueberry/dist/reactIcons/OutlineArrowLeftOne"
import OutlineArrowLeft from "iq-blueberry/dist/reactIcons/OutlineArrowLeft"
import PrivacyPolicy from "@mkt/ui/components/common/PrivacyPolicy"

import * as S from "./styles"

const ActionButtons = ({
  formData,
  onGoBack,
  onSubmit,
  showBackButton = true,
  beforeSubmitStep,
  currentStep,
  payload,
  loading = false,
}) => {
  const { isFormValid } = formData
  const usedPayload = formData?.payload || payload
  const usedCurrentStep = formData?.currentStep || currentStep

  function submitHandler(e) {
    if (!!beforeSubmitStep) {
      beforeSubmitStep({
        currentStep: usedCurrentStep,
        isFormValid: isFormValid,
        payload: usedPayload,
      })
    }
    if (!!formData) {
      formData?.handleSubmit()
    } else if (!!onSubmit) {
      onSubmit()
    }
  }

  return (
    <div className="action-buttons" css={S.actionButtons}>
      <div className="action-buttons__holder" css={S.actionButtonsHolder}>
        <Conditional
          condition={!!showBackButton}
          renderIf={
            <div className="action-buttons__left" css={S.actionButtonsLeft}>
              <Button
                expand="x"
                size="large"
                onClick={onGoBack}
                type="text"
                Icon={OutlineArrowLeft}
              >
                Voltar
              </Button>
            </div>
          }
        />
        <div className="action-buttons__right" css={S.actionButtonsRight}>
          <Button
            expand="x"
            size="large"
            onClick={submitHandler}
            // Icon={OutlineArrowLeftOne}
            justify="center"
            iconRight
            disabled={!isFormValid}
            loading={loading}
          >
            Peça já
          </Button>
        </div>
      </div>
      <div className="action-buttons__holder" css={S.actionButtonsHolder}>
        <PrivacyPolicy />
      </div>
    </div>
  )
}

ActionButtons.propTypes = {
  formData: PropTypes.shape({
    isFormValid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }),
  onGoBack: PropTypes.func,
  beforeSubmitStep: PropTypes.func,
}

export default ActionButtons
