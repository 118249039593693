import { Text } from "iq-blueberry"
import { useFirebolt } from "@iq-firebolt/client"
import BlueberryTheme from "@iq-firebolt/blueberry-theme"
import { StepForm, Wizard } from "@iq-firebolt/client"

import FormLayout from "@mkt/ui/components/common/Form/FormLayout"
import ActionButtons from "@mkt/ui/components/common/Form/ActionButtons"
import SkeletonLoader from "@mkt/ui/components/common/SkeletonLoader"
import DigioRedirectModal from "../DigioRedirectModal"

import useSoftLeadForm from "./hook"
import fbtPresets from "./fbt-presets"
import * as S from "./styles"

const DefaultStep = ({
  isShowingRequiredFieldMessage,
  handleFormSubmit,
  handleFocusField,
  isLoadingSortingHat,
  handleBeforeSubmit,
  autofillData,
  callSHOnblurField,
  callSHVOnChangeField,
}) => (
  <>
    {isShowingRequiredFieldMessage && (
      <Text as="p" defaultColor="common" variant="body-small">
        <Text as="span" variant="body-small">
          *
        </Text>{" "}
        Indica que o campo é obrigatório
      </Text>
    )}

    <StepForm
      theme={BlueberryTheme}
      onSubmit={handleFormSubmit}
      className="form-flow__firebolt"
      onChangeField={callSHVOnChangeField}
      css={S.formFlowFirebolt}
      autoFill={{ ...autofillData }}
      onFocusField={handleFocusField}
      onBlurField={callSHOnblurField}
      addons={{ uiPropsPresets: [fbtPresets] }}
      customActionsChild={({ formData }) => (
        <ActionButtons
          formData={formData}
          loading={isLoadingSortingHat}
          beforeSubmitStep={handleBeforeSubmit}
          showBackButton={false}
        />
      )}
    />
  </>
)

const SoftLeadForm = ({
  pageContext,
  dataSortingHat,
  isOpenSortingHat,
  setIsOpenSortingHat,
  setOpenLoaderRedirect,
  getDataSortinHatBySlug,
  handleSetDataSortingHat,
  choosenCard,
  content,
  currentPayload,
  isShowingTitle = true,
  isShowingRequiredFieldMessage = true,
  ComponentButton,
  setFormFocused,
  setSortingHatDecision,
  sortingHatDecision
}) => {
  const {
    handleFocusField,
    handleBeforeSubmit,
    isLoadingSortingHat,
    handleFormSubmit,
    stopLoaders,
    autofillData,
    callSHVOnChangeField,
    callSHOnblurField,
    handleBeforeChangeStep,
  } = useSoftLeadForm({
    pageContext,
    choosenCard,
    content,
    dataSortingHat,
    getDataSortinHatBySlug,
    handleSetDataSortingHat,
    setIsOpenSortingHat,
    isOpenSortingHat,
    setFormFocused,
    setSortingHatDecision,
    sortingHatDecision
  })

  const titles = {
    default: "Peça já o seu",
    itauPJ: "Abra sua conta PJ",
    itauPF: "Abra sua conta corrente",
  }

  const verificationTitle =
    pageContext?.slug === "itau-pj"
      ? titles.itauPJ
      : pageContext?.slug === "itau-pf"
      ? titles.itauPF
      : `${titles.default} ${content?.title}`

  const { currentStep } = useFirebolt()

  const isDigioFinal = currentStep?.data?.slug === "digio_final"

  return (
    <FormLayout css={S.formFlow} className="form-flow">
      <div
        className="form-flow__container"
        css={S.formFlowContainer}
        data-scroll="formViewed"
      >
        {isShowingTitle && !isDigioFinal && (
          <Text
            as="h2"
            defaultColor="title"
            variant="heading-three"
            id="form-title"
          >
            {verificationTitle}
          </Text>
        )}
        <div>
          <Wizard
            fallback={<SkeletonLoader submitting={true} />}
            onBeforeChangeStep={handleBeforeChangeStep}
          >
            <Wizard.Step
              match="*"
              component={DefaultStep}
              isShowingRequiredFieldMessage={isShowingRequiredFieldMessage}
              handleFormSubmit={handleFormSubmit}
              handleFocusField={handleFocusField}
              isLoadingSortingHat={isLoadingSortingHat}
              handleBeforeSubmit={handleBeforeSubmit}
              autofillData={autofillData}
              callSHOnblurField={callSHOnblurField}
              callSHVOnChangeField={callSHVOnChangeField}
            />
            <Wizard.Step
              match={{ slug: "digio_final" }}
              component={DigioRedirectModal}
              isOpenSortingHat={isOpenSortingHat}
            />
          </Wizard>
        </div>
      </div>
    </FormLayout>
  )
}

export default SoftLeadForm
