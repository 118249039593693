import { useFirebolt } from '@iq-firebolt/client'

export default function useHasTransitionError() {
  const { remoteErrors } = useFirebolt()

	const hasTransitionError = (error, errorList = remoteErrors) => {
		const remoteError = errorList[0]?.validationResults[0].message
		return error.includes(remoteError)
	}

  return {
    hasTransitionError,
  }
}
