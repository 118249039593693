import { useEffect } from "react"
import { navigate } from "gatsby"

import { affiliatePixel, affiliateScript } from "../../utils/alohomora"
import getQueryParams from "../../utils/getQueryParams"

import { makeRequestAlohomora } from "../services/alohomora"
import { makeRequestIp } from "../services/jsonip"

import useAnalytics from "./useAnalytics"

const useAlohomora = ({ content, location }) => {
  const { anonymousId } = useAnalytics()
  const utmSourceValue = "afilio"
  const utmMediumValue = "afiliados"
  const { utm_source: utmSourceParam, utm_medium: utmMediumParam } =
    getQueryParams()

  async function handleGetAlohomora({ capturedData }) {
    const ip = await makeRequestIp()
    const { full_name, email, cpf, id } = capturedData
    const dataRaw = {
      deviceID: anonymousId,
      proposalId: id,
      eventSlug: "identify",
      name: full_name,
      email: email,
      ip: ip,
      cpf: cpf,
      affiliate: utmSourceParam,
    }

    const response = await makeRequestAlohomora(dataRaw)

    const transactionId = id
    const urlProduct = window.location.href
    const leadValue = response?.data?.value
    const isValid = leadValue % 2 === 0

    if (isValid) {
      affiliateScript({
        src: `https://secure.afilio.com.br/mastertag.php?progid=3555&type=transaction&id_partner=iqcartões&transaction_id=${transactionId}&url_product=${urlProduct}`,
      })
      affiliatePixel({
        src: `https://p.afilio.com.br/?tra_id=${transactionId}&act_id=1364&id_click=${""}`,
      })
    }

    return response
  }

  function appendAfilioUtm() {
    const utms = JSON.parse(sessionStorage.getItem("utms_afiliate"))
    const hasSource = !utmSourceParam && utmSourceParam !== utmSourceValue
    const hasMedium = !utmMediumParam && utmMediumParam !== utmMediumValue
    const isValid =
      hasSource && utms?.utm_source && hasMedium && utms?.utm_medium

    if (isValid) {
      let url = new URL(window.location.href)
      let params = new URLSearchParams(url.search)
      params.set("utm_source", utmSourceValue)
      params.set("utm_medium", utmMediumValue)

      navigate(`${location.pathname}?${params.toString()}`)
    }
  }

  function saveAfilioUtm() {
    if (
      utmSourceParam === utmSourceValue &&
      utmMediumParam === utmMediumValue
    ) {
      sessionStorage.setItem(
        "utms_afiliate",
        JSON.stringify({
          utm_source: utmSourceValue,
          utm_medium: utmMediumValue,
        })
      )
    }
  }

  function handleAlohomoraTrack() {
    const { utm_source: utmSourceParam } = getQueryParams()
    const affiliateName = "afilio"
    const componentValue = content?.component
    const componentName = "marketplace_product_card"
    const pathname = location?.pathname
    const pageName = "/cartao-de-credito/destaques-do-mes"
    const url = location?.href

    if (utmSourceParam === affiliateName) {
      componentValue === componentName &&
        affiliateScript({
          src: `https://secure.afilio.com.br/mastertag.php?progid=3555&type=basket&id_partner=iqcartões&url_product=${url}`,
        })
      pathname === pageName &&
        affiliateScript({
          src: `https://secure.afilio.com.br/mastertag.php?progid=3555&type=homepage&id_partner=iqcartões&url_product=${url}`,
        })
    }
  }

  useEffect(() => {
    saveAfilioUtm()
  }, [])

  useEffect(() => {
    appendAfilioUtm()
  }, [])

  useEffect(() => {
    handleAlohomoraTrack()
  }, [])

  return { handleGetAlohomora }
}

export default useAlohomora
