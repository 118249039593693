const formatAutofillUrl = ({ url, payload }) => {
  let autofillData = JSON.stringify({
    full_name: { value: payload.full_name, mask: "" },
    cpf: { value: payload.cpf, mask: "cpf" },
    email: { value: payload.email, mask: "" },
    main_income: {
      value: String(payload.main_income),
      mask: "money",
    },
    main_phone: {
      value: String(payload.main_phone),
      mask: "phone_number",
    },
  })

  autofillData = autofillData.replace(new RegExp('"', "g"), "'")
  autofillData = encodeURIComponent(autofillData)
  autofillData = btoa(autofillData)
  autofillData = autofillData.replace(new RegExp("=", "g"), "")

  url.searchParams.set("autofill", autofillData)

  return url.href
}

export default formatAutofillUrl
