


export default function clearCurrencyValue(value){
    const safeValue = value || ""
    const withoutDot = safeValue.replace(".", "")
    const withReplacedComma = withoutDot.replace(",", ".")
    const onlyNumbers = withReplacedComma.replace(/[^0-9\.-]+/g,"")

    return Number(onlyNumbers)
}