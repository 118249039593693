import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"

export const actionButtons = css`
  margin-top: 32px;

  ${above["m"]} {
    margin-top: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    padding-top: 32px;
  }
`

export const actionButtonsHolder = css`
  &:first-of-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    ${above["m"]} {
      margin-bottom: 0;
    }
  }
`

export const actionButtonsLeft = css`
  width: 100%;
  display: none;

  ${above["m"]} {
    width: 197px;
    display: block;
  }
`

export const actionButtonsRight = css`
  width: 100%;
  margin-left: auto;

  ${above["m"]} {
    width: 197px;
  }
`
