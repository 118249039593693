// masks
import CPFMask from "@iq-firebolt/br-addons/src/masks/CPF"
import BRCurrency from "@iq-firebolt/br-addons/src/masks/currency"
import { BRPhoneMask } from "@iq-firebolt/br-addons/src/masks/phone"

const UIPropsPresetsList = {
  "br-cpf": {
    placeholder: "000.000.000-00",
    label: "CPF",
    mask: CPFMask,
    useNumericKeyboard: true,
  },
  "br-currency": {
    placeholder: "R$ 00.000,00",
    mask: BRCurrency,
    useNumericKeyboard: true,
  },
  "br-phone": {
    htmlType: "tel",
    label: "Celular com DDD",
    placeholder: "(00) 00000-0000",
    mask: BRPhoneMask,
    useNumericKeyboard: true,
  },
}

export default {
  name: "fbt-presets",
  presets: UIPropsPresetsList,
}
