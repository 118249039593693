import { APP_NAME } from "gatsby-env-variables"
import { useState, useEffect } from "react"

import { useFirebolt } from "@iq-firebolt/client"

//hooks
import useRedirects from "@mkt/ui/hooks/useRedirects"
import useAlohomora from "@mkt/ui/hooks/useAlohomora"
import useHasTransitionError from "@mkt/ui/hooks/useHasTransitionError"
import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import { useTrack } from "@mkt/analytics/redline/track"

// Utils
import getUrlParams from "@mkt/utils/getUrlParams"
import getAnonymousId from "@mkt/utils/getAnonymousId"
import { getCorrelationId, getDecodedFireboltStore } from "@mkt/analytics/redline/utils"
import  isDev  from  "@mkt/utils/isDev"
import isClient from '@mkt/utils/isClient'

// Services
import { pidgeyEmailDelivery } from "@mkt/ui/services/pidgey"
import { setCookie } from "@mkt/utils/cookie"

export default function useSoftLeadHandlers({
  choosenCard,
  content,
  location,
  dataSortingHat,
  getDataSortinHatBySlug,
  handleSetDataSortingHat,
  setIsOpenSortingHat,
  setIsSendingStep,
  setIsLoadingSortingHat,
  currentStep,
  goNextStep,
  formflowMetadata,
  form,
  dispatchGlobalError,
  shV3Decision,
  setSortingHatDecision,
}) {
  const { handleGetAlohomora } = useAlohomora({ content, location })

  const {
    getUrlWithAnonymousId,
    makeHandleRedirect,
    handleSortingHatRedirect,
  } = useRedirects({
    content,
  })

  const { hasTransitionError } = useHasTransitionError()
  const suggestionErrorSlug = "should_show_sortinghat"
  const hasSuggestionError = hasTransitionError(suggestionErrorSlug)

  const [hasStartedForm, setHasStartedForm] = useState(false)
  const [correlationId, setCorrelationId] = useState()
  const { capturedData, formEndPayload, remoteErrors, lastSentPayload } =
    useFirebolt()

  const { track } = useTrack()
  const { fireboltId } = useAnalytics()

  useEffect(() => {
    setCorrelationId(getCorrelationId())
  }, [])

  useEffect(() => {
    const formData = {
      ...lastSentPayload,
      ...choosenCard,
      issuer: content?.issuer,
      partner: APP_NAME,
      product_slug: content?.product_slug,
    }

    if (hasSuggestionError) {
      handleSortingHat({ formData, payload: lastSentPayload })
    }
  }, [remoteErrors])

  async function handleEndForm({ capturedData, webhookResult }) {
    const utmMediumParam = getUrlParams().get("utm_medium")
    const utmSourceParam = getUrlParams().get("utm_source")
    const affiliates = "afiliados"
    const affiliateName = "afilio"
    const isNegatived = capturedData.bad_credit === "true"
    const mobiPayload = {
      externalId: webhookResult?.processedData?.ItauExternalID,
      token: webhookResult?.processedData?.token,
    }

    if (utmMediumParam === affiliates && utmSourceParam === affiliateName) {
      await handleGetAlohomora({ capturedData })
    }

    const safeCapturedData = capturedData || {}
    const CDChoosenCard = safeCapturedData.choosen_card
    const acceptedSHOffer = !!CDChoosenCard && choosenCard.choosen_card !== safeCapturedData.choosen_card;
    const isBBCard = capturedData.choosen_card === 'cards-bancodobrasil-ourocardfacil'

    if (acceptedSHOffer) {
      const workflowId = content?.workflow_id || "w1"
      track("decisionAccepted", {
        accepted: {
          cardSlug: capturedData.choosen_card,
          partner: capturedData.issuer,
        },
        shVersion: "v3",
        correlationId,
        workflowId,
      })

      const redirectUrl = handleSortingHatRedirect({
        mobiPayload,
        suggestionCard: dataSortingHat,
        payload: capturedData,
        application: 'iq'
      })

      const payloadToSent = {
        ...capturedData,
        redirectSlug: capturedData.choosen_card,
        originSlug: capturedData.product_slug,
        redirectUrl: redirectUrl,
        fireboltId: fireboltId,
        has_credit_restriction: isNegatived,
      }

      track("redirectRequested", payloadToSent)

      if (!isBBCard) {
        await track("proposalStarted", {
          initialSlug: capturedData.product_slug,
          finalSlug: capturedData.choosen_card,
          category: content.product_category,
          redirectUrl: redirectUrl,
          fireboltId: fireboltId
        }).catch(console.error)
      } else {
        setCookie({
          items: [{ name: "initial_slug",
          value: capturedData.product_slug }],
        })
      }

      window.location.href = redirectUrl

      return redirectUrl
    }

    const refusedSHSuggestion =
      dataSortingHat.slug === "click-platinum" ? "click-platinum" : false

    makeHandleRedirect({
      choosenCard,
      content,
      mobiPayload,
      refusedSHSuggestion,
      id: capturedData?.id,
      cameFromSortingHat: true,
      trackFormErrored
    })
  }


  function trackFormErrored(errors, payload) {
    const isNegatived = payload.bad_credit === "true"
    const payloadToSent = {
      ...payload,
      slug: payload.choosen_card,
      has_credit_restriction: isNegatived,
    }
    const withAnonymousID = getUrlWithAnonymousId({
      anonymousId: getAnonymousId(),
      choosenCard,
      content,
    })

    track("formErrored", {
      errors,
      ...payloadToSent,
      ...form?.data,
      ...formflowMetadata,
      ...currentStep.data,
      url: withAnonymousID,
    })
  }

  function handleFocusField(e) {
    if (!hasStartedForm) {
      track("formStarted", {
        ...e,
        ...form?.data,
        ...formflowMetadata,
      })
      setHasStartedForm(true)
    }
  }

  function stopLoaders() {
    setIsLoadingSortingHat(false)
    setIsSendingStep(false)
  }

  function handleBeforeSubmit(formData) {
    const { payload } = formData
    const isNegatived = payload.bad_credit === "true"
    const isLastStep = currentStep?.position === formflowMetadata?.steps.length
    const payloadToSent = {
      ...payload,
      slug: payload.choosen_card,
      has_credit_restriction: isNegatived,
    }

    if (isLastStep || formflowMetadata?.lastStep === "digio_final") {
      const withAnonymousID = getUrlWithAnonymousId({
        anonymousId: getAnonymousId(),
        choosenCard,
        content,
      })

      track("formSubmitted", {
        ...payloadToSent,
        ...form?.data,
        ...formflowMetadata,
        ...currentStep.data,
        url: withAnonymousID,
      })
    }
  }

  const handleDispatchGlobalError = ({ response, formData }) => {
    const getGenericError = response?.errors.find((err) => err.slug === "")

    if (getGenericError?.slug === "") {
      dispatchGlobalError()
    }
  }

  const getApplication = () => {
    const { href } = isClient() && window.location
    const applications = {
      "cartoes.com.br": "ccbr",
      "acordocerto.com.br": isDev ? "acordocerto-dev" : "acordocerto",
      "/acordocerto/": isDev ? "acordocerto-dev" : "acordocerto",
      "iq.com.br": "iq",
      "azulis.com.br": "azulis",
      "localhost:8000": "iq",
      "localhost:8001": "ccbr",
      "localhost:8002": "azulis",
    }

    const currentMarketplace = Object.keys(applications).find((applicationKey) => href?.includes(applicationKey))

    return { application: applications[currentMarketplace] }
  }

  const application = getApplication()
  const defaultKeys = {...choosenCard, ...application}

  async function handleFormSubmit(payload) {
    setIsSendingStep(true)

    const formData = {
      ...payload,
      ...choosenCard,
      issuer: content?.issuer,
      partner: APP_NAME,
      product_slug: content?.product_slug,
      ...defaultKeys
    }

    try {
      pidgeyEmailDelivery(formData)

      // TODO: SH_BREAK_CHANGE
      const SHDecision = await shV3Decision(payload)

      setSortingHatDecision(SHDecision.result.decisions)

      const currentValue = "current"
      const safeTrack = !!getDataSortinHatBySlug(SHDecision?.firstDecisionItem, SHDecision?.resultType)
        ? SHDecision?.firstDecisionItem
        : currentValue

      const isCurrent = safeTrack === currentValue

      if (isCurrent) {
        const response = await goNextStep(formData)

        if (response?.errors?.length) {
          setIsSendingStep(false)
        } else {
          if(formData.choosen_card === 'digio' && !hasSuggestionError) {

            const uniqueId = getDecodedFireboltStore('digio-cards')?.unique_id

            await track("proposalStarted", {
              initialSlug: content.product_slug,
              finalSlug: content.product_slug,
              category: content.product_category,
              redirectUrl: window.location.href,
              fireboltId: fireboltId || uniqueId
            }).catch(console.error)
          }
        }

        return handleDispatchGlobalError({ response, formData })
      }

      setIsOpenSortingHat(true)

      handleSetDataSortingHat({
        slug: safeTrack,
        payload: formData,
        shVersion: "v3",
        preventContinue:
          SHDecision?.result?.decisions?.[0]?.prevent_continue || false,
      })
    } catch (error) {
      setIsSendingStep(false)
      console.error("reCaptcha error:", error)
    }
  }

  async function handleSortingHat({ payload, formData }) {
    const SHDecision = await shV3Decision(payload)
    const isCurrent = !getDataSortinHatBySlug(SHDecision?.firstDecisionItem)

    if (isCurrent && !hasSuggestionError) {
      const response = await goNextStep(formData)

      if (response?.errors?.length) {
        setIsSendingStep(false)
      }

      const responseHasSuggestionError = response?.errors
        ? hasTransitionError(suggestionErrorSlug, response?.errors)
        : false
      if (!responseHasSuggestionError) {
        handleDispatchGlobalError({ response, formData })
      }
    } else {
      const slug =
        isCurrent && hasSuggestionError
          ? SHDecision?.result.decisions[1].slug
          : SHDecision?.firstDecisionItem

      setIsOpenSortingHat(true)

      handleSetDataSortingHat({
        slug: slug,
        payload: formData,
        shVersion: "v3",
      })
    }
  }

  async function digioSHRedirect() {
    const workflowId = content?.workflow_id || "w1"
    const isNegatived = capturedData.bad_credit === "true"

      await track("decisionAccepted", {
        accepted: {
          cardSlug: capturedData.choosen_card,
          partner: capturedData.issuer,
        },
        shVersion: "v3",
        correlationId,
        workflowId,
      })

      const redirectUrl = handleSortingHatRedirect({
        mobiPayload: {
          externalId: '',
          token: '',
        },
        suggestionCard: dataSortingHat,
        payload: capturedData,
        application: 'iq'
      })

      await track("redirectRequested", {
        ...capturedData,
        redirectSlug: capturedData.choosen_card,
        originSlug: capturedData.product_slug,
        redirectUrl: redirectUrl,
        fireboltId: fireboltId,
        has_credit_restriction: isNegatived,
      })

      window.location.href = redirectUrl

      return redirectUrl
  }

  function handleBeforeChangeStep(
    proceed,
    { leavingStep, stepPayload, enteringStep, ...props }
  ) {
    const safeStepPayload = stepPayload || {}
    const isNext = enteringStep.position > leavingStep.position

    if (isNext) {
      track("formContinued", {
        ...safeStepPayload,
        ...leavingStep?.data,
        capturedData,
        formEndPayload,
        fields: leavingStep?.data?.fields,
        currentStep: leavingStep,
      })
    }

    const isDigioLastStep = enteringStep?.data?.slug === "digio_final"
    const hasDigioSHRedirect = dataSortingHat?.redirect_list?.[0]?.redirect || dataSortingHat?.links?.[0]

    console.log('dataSortingHat', dataSortingHat)
    const isDigioSortingHatRedirect = isDigioLastStep && hasDigioSHRedirect

    if (isDigioSortingHatRedirect) {
      return digioSHRedirect()
    }

    proceed()
  }

  return {
    handleEndForm,
    handleFocusField,
    handleFormSubmit,
    handleBeforeSubmit,
    stopLoaders,
    handleBeforeChangeStep,
    hasStartedForm,
  }
}
