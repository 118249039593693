export const affiliateScript = ({ src }) => {
  const script = document.createElement("script")
  script.src = src
  script.type = "text/javascript"
  script.async = true

  document.body.appendChild(script)

  return script
};

export const affiliatePixel = ({ src }) => {
  const img = document.createElement("img")
  img.src = src
  img.style.border = "0"
  img.width = 1
  img.height = 0

  document.body.appendChild(img)

  return img
}