import isClient from "./isClient"

export const isAndroid = () => {
  if (!isClient()) return

  let userAgent = navigator.userAgent || navigator.vendor || window["opera"]

  return /android/i.test(userAgent)
}

export const isIOS = () => {
  if (!isClient()) return

  const isIPadOniOS13 =
    navigator.userAgent.includes("Mac") && "ontouchend" in document

  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) || isIPadOniOS13
  )
}
