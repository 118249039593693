const getSortingHatUrlParams = ({
	fireboltId,
	anonymousId,
	forceHasParameters = false,
	suggestionUrl = ''
}) => {
	if(suggestionUrl.indexOf('cartoes.itau.com.br') > -1) {
		return ''
	}

  const urlHasParameters = suggestionUrl.indexOf('?') > -1

  const firstCharacter = forceHasParameters || urlHasParameters ? '&' : '?'

  const SHParams = [
		{
			param: `aff_unique1`,
			value: anonymousId,
		},
		{
      param: `aff_unique2`,
			value: fireboltId,
    },
		{
      param: `form_id`,
			value: fireboltId,
    },
    {
      param: `utm_term`,
			value: anonymousId,
    },
  ]

  const filterExistingParams = (item) => {
    return suggestionUrl.indexOf(item?.param) === -1
  }

  const filteredSHParams = SHParams.filter(filterExistingParams) 

  const stringParams = filteredSHParams.map(item => {
    return `${item?.param}=${item?.value}`
  })

  const finalParams = `${firstCharacter}${stringParams.join('&')}`


	return finalParams
}

export default getSortingHatUrlParams