import { useState, useEffect } from "react"
import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import { useTrack } from "@mkt/analytics/redline/track"
import { useSortingHatV3 } from "iq-sorting-hat-client-sdk"
import { isAndroid, isIOS } from "@mkt/utils/getMobileOS"

import isDev from "@mkt/utils/isDev"

export default function useSortingHat({
  autofillData,
  hasFormAutofilledFromUrl,
  content,
  choosenCard,
}) {
  const [hasRunnedSHAfterAutofill, setHasRunnedSHAfterAutofill] =
    useState(false)
  const { track } = useTrack()
  const { anonymousId, fireboltId, instanceId, sessionIdTglr } = useAnalytics()
  const workflowId = content?.workflow_id || "w1"
  useEffect(executeAutofill, [hasFormAutofilledFromUrl, anonymousId])

  const trackEvent = async () => {
    const eventPayload = {
      cardSlug: choosenCard?.choosen_card,
      partner: content?.issuer,
    }

    track("decisionEnriched", eventPayload)
  }

  const decisionRequestEvent = async (props) => {
    const eventPayload = {
      partner: content?.issuer,
      cardSlug: choosenCard?.choosen_card,
      shVersion: "v3",
      workflowId,
      ...props,
    }

    track("decisionRequested", eventPayload)
  }

  const decisionReceivedEvent = async (props, decision) => {
    const shResult = decision?.result?.decisions

    const payloadDecisionProducts = shResult.map((sugestion) => {
      return {
        productId: sugestion.id,
        productBrand: sugestion.brand,
        productCategory: sugestion.category,
      }
    })

    const eventPayload = {
      partner: shResult[0]?.brand,
      cardSlug: shResult[0]?.id,
      decisionProducts: payloadDecisionProducts,
      shVersion: "v3",
      enabled: decision?.enabled,
      workflowId,
      ...props,
    }

    track("decisionOutcomeReceived", eventPayload)
  }

  const deviceToSHTrack = isAndroid() ? "android" : isIOS() ? "ios" : "web"

  const { shTrack: shV3Track, shDecision: shV3Decision } = useSortingHatV3({
    appName: APP_NAME,
    workflowId,
    trackEvent,
    decisionRequestEvent,
    decisionReceivedEvent,
    isDev,
    extraTrackData: {
      anonymousId,
      instanceId,
      sessionId: sessionIdTglr,
      fireboltId,
      partner: content?.issuer,
      choosenCard: choosenCard?.choosen_card,
      device: deviceToSHTrack,
    },
  })

  function executeAutofill() {
    const shConditions = hasFormAutofilledFromUrl && !!anonymousId

    if (shConditions && !hasRunnedSHAfterAutofill) {
      Object.entries(autofillData).forEach(([key, value]) => {
        if (key === "income" || key === "choosen_card") return
        shV3Track(key, value, autofillData, true)
      })
      setHasRunnedSHAfterAutofill(true)
    }
  }

  function callSHVOnChangeField(inputConfig, { value, isValid }, formPayload) {
    if (inputConfig?.slug !== "full_name" && isValid)
      shV3Track(inputConfig?.slug, value, formPayload, isValid, true)
  }

  function callSHOnblurField(inputConfig, inputValue, formPayload, isValid) {
    if (inputConfig?.slug === "full_name")
      shV3Track(inputConfig?.slug, inputValue, formPayload, isValid, true)
  }

  return {
    shV3Decision,
    callSHVOnChangeField,
    callSHOnblurField,
  }
}
