import getStoryblokIntegrations from "../../utils/getStoryblokIntegrations"

const useStoryblokIntegrations = ({ content }) => {
  function formatBolleanValues(arr) {
    return Object.keys(arr).reduce((acc, key) => {
      return key === "active"
        ? (acc = { ...acc, [key]: arr[key] === "true" })
        : (acc = { ...acc, [key]: arr[key] })
    }, {})
  }

  function getIntegrationProps(component) {
    const getPropsByComponent = getStoryblokIntegrations(
      content?.integrations_list
    )[component]

    if (!getPropsByComponent) {
      return
    }

    const getNewValues = formatBolleanValues(getPropsByComponent)

    return getNewValues || {}
  }

  return {
    getIntegrationProps,
  }
}

export default useStoryblokIntegrations
