/* globals APP_NAME */
import { useRef, useState, useEffect } from "react"
import useStoryblokIntegrations from "@mkt/ui/hooks/useStoryblokIntegrations"
import { useFirebolt } from "@iq-firebolt/client"
import { useTrack } from "@mkt/analytics/redline/track"
import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import useFireboltMetadata from "@mkt/ui/hooks/useFireboltMetadata"

import useSoftLeadHandlers from "./handlers"
import useAutofill from "./useAutofill"
import useSortingHat from "./useSortingHat"
import isClient from "@mkt/utils/isClient"
import getQueryParams from "@mkt/utils/getQueryParams"

const isClickCard = () => {
  const { pathname } = window.location
  return pathname.includes("itaucard-click-platinum-master")
}

export const getUsedForm = () => {
  if (!isClient()) return ""

  const { form } = getQueryParams()
  return isClickCard() && !!form ? form : "iq-marketplace"
}

export default function useSoftLeadForm({
  pageContext,
  choosenCard,
  content,
  dataSortingHat,
  getDataSortinHatBySlug,
  handleSetDataSortingHat,
  setIsOpenSortingHat,
  isOpenSortingHat,
  setFormFocused,
  setSortingHatDecision,
  sortingHatDecision,
}) {
  const _reCaptchaRef = useRef(null)
  const {
    formFlowHasBeenFinished,
    formEndPayload,
    currentStep,
    goNextStep,
    formflowMetadata,
    lastSentPayload,
  } = useFirebolt()
  const { getIntegrationProps } = useStoryblokIntegrations({ content })
  const propsRecaptcha = getIntegrationProps("component_recaptcha")
  const isReCaptchaActive = propsRecaptcha?.active

  const [isSendingStep, setIsSendingStep] = useState(false)
  const [isLoadingSortingHat, setIsLoadingSortingHat] = useState()
  const [eventTriggered, setEventTriggered] = useState(false)

  const { track } = useTrack()
  const { form, dispatchGlobalError } = useAnalytics()
  const { autofillData, hasFormAutofilledFromUrl } = useAutofill()

  const { shV3Decision, callSHVOnChangeField, callSHOnblurField } =
    useSortingHat({
      pageContext,
      autofillData,
      hasFormAutofilledFromUrl,
      content,
      choosenCard,
    })

  const {
    handleEndForm,
    handleFocusField,
    handleBeforeSubmit,
    handleFormSubmit,
    handleBeforeChangeStep,
    stopLoaders,
    hasStartedForm,
  } = useSoftLeadHandlers({
    choosenCard,
    content,
    dataSortingHat,
    getIntegrationProps,
    isReCaptchaActive,
    _reCaptchaRef,
    getDataSortinHatBySlug,
    handleSetDataSortingHat,
    setIsLoadingSortingHat,
    setIsOpenSortingHat,
    setIsSendingStep,
    currentStep,
    goNextStep,
    formflowMetadata,
    form,
    dispatchGlobalError,
    shV3Decision,
    setSortingHatDecision,
  })

  useEffect(() => {
    if (!!formFlowHasBeenFinished && formEndPayload) {
      handleEndForm(formEndPayload || {})
    }
  }, [formFlowHasBeenFinished])

  useEffect(() => {
    if (setFormFocused) {
      setFormFocused(hasStartedForm)
    }
  }, [hasStartedForm])

  useEffect(() => {
    const context =
      sortingHatDecision?.length > 0
        ? sortingHatDecision?.map((data) => {
            return {
              cardSlug: data?.slug,
              partner: data?.partner,
            }
          })
        : null

    if (isOpenSortingHat && context && !eventTriggered) {
      track("modalViewed", {
        suggestionContext: context,
        current: {
          cardSlug: choosenCard.choosen_card,
          partner: content.issuer,
          shVersion: "v3",
        },
      })
      setEventTriggered(true)
    }
  }, [sortingHatDecision, dataSortingHat, isOpenSortingHat])

  useFireboltMetadata({ pageContext })

  return {
    handleFocusField,
    _reCaptchaRef,
    handleBeforeSubmit,
    isLoadingSortingHat,
    isReCaptchaActive,
    handleFormSubmit,
    isSendingStep,
    stopLoaders,
    autofillData,
    shV3Decision,
    callSHVOnChangeField,
    callSHOnblurField,
    handleBeforeChangeStep,
  }
}
