const getStoryblokIntegrations = (list = []) => {
	return list?.reduce((acc, item) => {
		const { component, ...props } = item

		const data = Object.keys(props).reduce((values, key) => {
			const isKeyValid = !key.startsWith('_')
			return values && isKeyValid ? {...values, [key]: props[key]} : values
		}, {})

		return acc ? {...acc, [component]: data} : acc
	}, [])
}

export default getStoryblokIntegrations

