import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"

export const content = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h6 {
    padding-top: 16px;

    ${above["m"]} {
      padding-top: 25px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .iq-btn {
    background-color: #5775D0;
    border: 2px solid #5775D0;
    font-weight: 600;
  }
`

export const infos = css`
  padding: 48px 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const textBold = css`
  font-weight: 600;
`

export const fisrtSubtitle = css`
  padding-top: 16px;
`

export const secondSubtitle = css`
  padding-top: 8px;
`