import axios from "axios"
import { APP_NAME } from "gatsby-env-variables"

import clearCurrencyValue from "@mkt/utils/clearCurrencyValue"
import isDev from "@mkt/utils/isDev"

export function pidgeyEmailDelivery(formPayload: Object): void {
  const apiRoot = isDev
    ? "https://pidgey.dev.iq.com.br"
    : "https://pidgey.iq.com.br"

  const formattedPayload = Object.entries(formPayload).reduce(
    (acc, [key, value]) => {
      switch (key) {
        case "full_name":
          return { ...acc, name: value }
        case "cpf":
          return { ...acc, document: value }
        case "main_phone":
          return { ...acc, phone: value }
        case "main_income":
          return { ...acc, income: clearCurrencyValue(value) }
        case "bad_credit":
          return { ...acc, [key]: value === "true" ? true : false }
        default:
          return { ...acc, [key]: value }
      }
    },
    {}
  )

  const businessSlug = ["iq", "cp"].includes(APP_NAME) ? "cards" : APP_NAME

  const requestBody = {
    ...formattedPayload,
    workspace_slug: APP_NAME,
    business_slug: businessSlug,
  }

  axios.post(`${apiRoot}/email-delivery`, requestBody)
}
