import getUrlParams from "./getUrlParams"
import replaceAppsflyerUrlParams from "./replaceAppsflyerUrlParams"

const formatUrlBasedOnUtm = ({
  redirectList,
  cardSlug,
  userProductId,
  anonymousId,
}) => {
  const currentUtmMedium = getUrlParams().get("utm_medium")
  const currentMediumKey = !!currentUtmMedium ? currentUtmMedium : "organic"

  const getCurrent = redirectList?.find(
    ({ param }) => param === currentMediumKey || param === "default"
  )

  const urlReplaced = replaceAppsflyerUrlParams({
    url: getCurrent?.redirect,
    cardSlug,
    userProductId,
    anonymousId,
  })

  return urlReplaced
}

export default formatUrlBasedOnUtm
