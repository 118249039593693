import { useState, useEffect } from "react"
import  isDev  from  "@mkt/utils/isDev"

import { getAutofillFromURL } from "./helpers"

export default function useAutofill() {
  const [autofillData, setAutofillData] = useState({})
  const [hasFormAutofilledFromUrl, setHasFormAutofilledFromUrl] =
    useState(false)

  useEffect(() => {
    _handleAutoFill()
  }, [])

  const _handleAutoFill = async () => {
    const responseAutofillData = getAutofillFromURL()
    
    if (responseAutofillData) {
      setAutofillData({ ...responseAutofillData })
      setHasFormAutofilledFromUrl(true)
    }
  }

  return {
    autofillData,
    hasFormAutofilledFromUrl,
  }
}
