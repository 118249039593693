import { keyframes } from "@emotion/react"

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

export const animeArcuiro = keyframes`
  0% {
    background-position: 100px;
		opacity: 0;
  }

  100% {
    background-position: 0;
		opacity: 1;
  }

`

export const animeShow = keyframes`
  0% {
    opacity: 0;
		transform: translateY(-20px);
  }

  100% {
    opacity: 1;
		transform: translateY(0px);
  }

`

export const shimmer = keyframes`
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
`

export const spin = keyframes`
	100% {
    transform: rotate(360deg);
  }
`

export const animeReverseShow = keyframes`
	0% {
		opacity: 0;
		transform: translateY(24px);
  }

	100% {
		opacity: 1;
		transform: translateY(0px);
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    background-color: transparent;
    transform: translateY(-35px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    background-color: #fff;
  }
`

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const overlayFade = keyframes`
  from {
    opacity: 0.01;
  }
  to {
    opacity: 0.7;
  }
`

export const openDrawerModal = keyframes`
  from {
    right: -590px;
  }
  to {
    right: 0;
  }
`
