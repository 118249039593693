import { isMobile } from "@mkt/utils/getDevice"
import { Button, Conditional } from "iq-blueberry"
import * as S from "./styles"

const SkeletonLoader = ({ submitting }) => {
  return isMobile() 
    ? <SkeletonLoaderMobile submitting={submitting} /> 
    : <SkeletonLoaderDesktop submitting={submitting}/>
}

const SkeletonLoaderMobile = ({ submitting }) => (
  <div className="skeleton-container" css={S.skeletonContainer}>
    <div className="shimmer title-line" css={S.titleLine} />
    <div className="shimmer text-line" css={S.textLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <div className="shimmer label-line" css={S.labelLine} />
    <div className="shimmer input-line" css={S.inputLine} />
    <Conditional
      condition={submitting}
      renderIf={
        <Button 
          loading 
          expand="x"
          size="large"
        />
      }
    />
  </div>
)

const SkeletonLoaderDesktop = ({ submitting }) => {
  return (
    <div className="skeleton-container" css={S.skeletonContainer}>
      <div className="shimmer title-line" css={S.titleLine} />
      <div className="shimmer text-line" css={S.textLine} />
      <div className="shimmer label-line" css={S.labelLine} />
      <div className="shimmer input-line" css={S.inputLine} />
      <div className="shimmer label-line" css={S.labelLine} />
      <div className="shimmer input-line" css={S.inputLine} />
      <div className="input-group" css={S.inputGroup}>
        <div>
          <div className="shimmer label-line" css={S.labelLine} />
          <div className="shimmer input-line" css={S.inputLine} />
        </div>
        <div>
          <div className="shimmer label-line" css={S.labelLine} />
          <div className="shimmer input-line" css={S.inputLine} />
        </div>
      </div>
      <div className="input-group" css={S.inputGroup}>
        <div>
          <div className="shimmer label-line" css={S.labelLine} />
          <div className="shimmer input-line" css={S.inputLine} />
        </div>
        <div>
          <div className="shimmer label-line" css={S.labelLine} />
          <div className="shimmer input-line" css={S.inputLine} />
        </div>
      </div>
      <div className="button-group" css={S.buttonGroup}>
        <div className="shimmer label-line"  css={S.labelLine} />
        <Conditional
          condition={submitting}
          renderIf={
            <Button
              loading 
              expand="x"
              size="large"
            />
          }
          renderElse={<div className="shimmer input-line" css={S.inputLine} />}
        />
      </div>
    </div>
  )
}

export default SkeletonLoader
