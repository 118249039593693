import { useFirebolt } from "@iq-firebolt/client"
import { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"

import useAnalytics from "./useAnalytics"
import formatMobiUrl from "../../utils/formatMobiUrl"
import getRandomHashMobi from "../../utils/getRandomHashMobi"
import dataHashsMobi from "../data/mobi-hashs.json"
import useStoryblokIntegrations from "./useStoryblokIntegrations"

// import getStoryblokIntegrations from "@mkt/utils/getStoryblokIntegrations"

export default function useFireboltMetadata({ pageContext }) {
  const { content } = pageContext
  const { getIntegrationProps } = useStoryblokIntegrations({ content })
  const { addRequestsMetadata, requestsMetadata, ...props } = useFirebolt()
  const { anonymousId, fbCookies, gaCookies, ttqCookies, sourceUrl, sessionId, fireboltId } = useAnalytics()
  const propsMobiCard = getIntegrationProps('component_mobi_card')

  useEffect(() => {
    const userAgent = navigator?.userAgent
    addRequestsMetadata("userAgent", userAgent)
  }, [])

  useEffect(() => {
    if (!requestsMetadata?.propsMobiCard && propsMobiCard) {
      const propsMobi = {
        ...propsMobiCard,
        itau_source_url: formatMobiUrl({
          anonymousId,
          fireboltId,
          cardId: propsMobiCard?.card_id,
          cardSlug: pageContext?.slug,
          cardType: propsMobiCard?.card_type,
          randomHash: getRandomHashMobi(dataHashsMobi),
        }),
      }
      addRequestsMetadata("propsMobiCard", propsMobi)
    }
  }, [propsMobiCard, fireboltId])

  useEffect(() => {
    const currentId = anonymousId ? anonymousId : uuidv4()

    addRequestsMetadata("anonymousId", currentId)
  }, [anonymousId])

  useEffect(() => {
    if (!!fbCookies?.fbpId || !!fbCookies?.fbcId) {
      addRequestsMetadata("fbCookies", fbCookies)
    }
  }, [fbCookies])

  useEffect(() => {
    if (!!sourceUrl) {
      addRequestsMetadata("sourceUrl", sourceUrl)
    }
  }, [sourceUrl])

  useEffect(() => {
    if (!!gaCookies?.gaId) {
      addRequestsMetadata("gaCookies", gaCookies)
    }
  }, [gaCookies])

  useEffect(() => {
    if (!!ttqCookies?.ttqId) {
      addRequestsMetadata("ttqCookies", ttqCookies)
    }
  }, [ttqCookies])

  useEffect(() => {
    if (sessionId) {
      addRequestsMetadata("sessionId", sessionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])
}
